
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Home",
  components: { Header, Tail },
  setup() {
    const bkcolor = ref("white");
    const router = useRouter();
    const gotolink = (url: string) => {
      router.push(url);
    };
    const data = reactive({
      list: [
        {
          name: " General Terms & Conditions",
          links: [
            {
              name: "Read Here",
              link: "/",
            },
          ],
        },
        {
          name: "Privacy Policy",
          links: [
            {
              name: "General",
              link: "/",
            },
            {
              name: "California Privacy Notice",
              link: "/",
            },
            {
              name: "GLBA Privacy Notice",
              link: "/",
            },
          ],
        },
        {
          name: "Cookie Policy",
          links: [
            {
              name: "Read Here",
              link: "/",
            },
          ],
        },
        {
          name: "Prohibited Uses",
          links: [
            {
              name: "Read Here",
              link: "/",
            },
          ],
        },
        {
          name: "Roxe App User Agreement",
          links: [
            {
              name: "Read Here",
              link: "/",
            },
          ],
        },
        {
          name: "API User Agreement",
          links: [
            {
              name: "Read Here",
              link: "/",
            },
          ],
        },
        {
          name: "Beta Testing User Agreement",
          links: [
            {
              name: "Read Here",
              link: "/",
            },
          ],
        },
        {
          name: "Regulators",
          links: [
            {
              name: "Read Here",
              link: "/",
            },
          ],
        },
      ],
    });
    return { data, bkcolor, gotolink };
  },
});
